/*
 * @Author: dongjia
 * @Date: 2021-09-08 14:44:57
 * @LastEditTime: 2021-09-08 14:50:36
 * @LastEditors: aleaner
 * @Description: 用户等级积分记录接口
 * @FilePath: \organization-admin\api\member\member-income-records.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

// 获取用户记录列表数据
export const recordList = data => {
  return api({
    url: "/admin/incomes/record/index",
    method: "post",
    data
  });
};