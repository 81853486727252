<!--
 * @Author: dongjia
 * @Date: 2021-09-08 14:46:52
 * @LastEditTime: 2021-09-08 14:46:52
 * @LastEditors: aleaner
 * @Description: 用户等级积分记录筛选
 * @FilePath: \organization-admin\components\Member\IncomeRecordsFilter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false" collapseHeight="60">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <div class="filter-item">
        <div class="filter-item">
          <DatePeriodPicker label="创建时间：" start-prop="start_time" :start-time.sync="form.start_time"
            end-prop="end_time" :end-time.sync="form.end_time" />
        </div>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import LayoutFilter from "@/base/layout/LayoutFilter";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      positionList: [],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  ::v-deep.el-input__inner {
    width: 67px !important;
  }
}
</style>